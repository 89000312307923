import Button from '@mui/material/Button';
import "./Header.css";

export default function Header() {
    return (
      <>
      <div className='Header' style={{ backgroundColor: '#1b3a4b' }}>
        <Button variant="contained" size='large' style={{color: '#ddbea8'}}>Sky Notify</Button>
        <Button variant="outlined" size='large' style={{color: '#ddbea8'}}>About</Button>
        <Button variant="outlined" size='large' style={{color: '#ddbea8'}}>Contact Me</Button>
      </div>
      </>
    )
}
