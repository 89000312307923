import "./Footer.css"
import { Box } from "@mui/material";

function Footer() {
    return (
      <Box 
        className='Footer' 
        style={{ backgroundColor: '#1b3a4b' }}
        display={"flex"}
        height={"10rem"}
        >
        Goodbye
      </Box>
    )
}
export default Footer;