// client/src/App.tsx

import "./App.css";
import Header from "./components/Header"
import Footer from "./components/Footer"
import HomePage from "./components/HomePage"
import { Box, Stack } from "@mui/material";

export default function App() {


  return (
    <Stack direction={"column"}>
      <Box>

      <Header/>
      </Box>
      <Box>
      <HomePage/>

      </Box>
      <Box>
      <Footer/>

      </Box>
    </Stack>
  );
}