import { Polygon } from "react-leaflet";
import { latLng, LatLng } from "leaflet";

function AuroraViewLine( GeoMagneticWrapper: any) {

    // console.log(GeoMagneticWrapper)
    const topRight: LatLng = latLng(90, 180)
    const topLeft: LatLng = latLng(90, -180)
    let tracedLine = [topRight, topLeft]
    let longitude = -180
    while (longitude <= 180) {
      let point: LatLng = latLng(0, longitude)
      let distanceInKM = (point.distanceTo(GeoMagneticWrapper.GeoMagneticNorthPole)/1000)
      let geoMagneticLatitude = (90 - ((distanceInKM)/111))
      
      while (geoMagneticLatitude < GeoMagneticWrapper.projectedLatitude && point.lat < 180) {
        point.lat += .005
        distanceInKM = (point.distanceTo(GeoMagneticWrapper.GeoMagneticNorthPole)/1000)
        geoMagneticLatitude = (90 - ((distanceInKM)/111))
      }

      tracedLine.push(point)      
      longitude += 2
    }

    return (
      <Polygon 
        positions={tracedLine}
        pathOptions={{ color: "green" }}
        >
      </Polygon>
    )
}




export default AuroraViewLine;


